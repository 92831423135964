export const en = {
  general: {
    new: 'New',
    done: 'Done',
    open: 'Open',
    save: 'Save',
    edit: 'Edit',
    empty: 'Empty',
    close: 'Close',
    search: 'Search',
    file: 'File',
    filter: 'Filter',
    cancel: 'Cancel',
    create: 'Create',
    select: 'Select',
    building: 'Building',
    deploying: 'Deploying',
    loading: 'Loading',
    existing: 'Existing',
    last_updated: 'Last updated',
    form_data: 'Form Data',
    date_format: 'MM/DD/YYYY',
    email: 'Email',
    name: 'Name',
    country: 'Country',
    message: 'Message',
    type: 'Type',
    home: 'Home',
    welcome: 'Welcome',
    not_found: 'Not Found',
    oops: 'Oops',
  },
  permissions: {
    admin: 'Admin',
    push: 'Editor',
    pull: 'Public'
  },
  navbar: {
    preview: 'Preview',
    submit: 'Submit PR',
    update: 'Update PR',
    pending: 'Pending',
    submit_title: 'Submit changes for approval.',
    update_title: 'Update changes for approval.',
    pending_title: 'Change(s) pending approval.',
    language: 'Language',
    source: 'Source',
    login: 'Login with',
    exit: 'Exit',
		docs: 'Docs'
  },
  sidebar: {
    repos: 'Repos',
    users: 'Users',
    changes: 'Changes',
    documents: 'Documents',
    collections: 'Collections',
    submissions: 'Reports'
  },
  repos: {
    list_title: 'Welcome! Please select a repository.',
    select: 'Select Repo'
  },
  collections: {
    entry_name: 'Name',
    empty_state: 'Not found.'
  },
  users: {
    revoke: 'Revoke',
    access: 'Access',
    active: 'Active',
    blocked: 'Blocked',
    disabled: 'Disabled',
    name: 'Name',
    preview: 'Preview',
    status: 'Status',
    empty_state: 'There are no users for this repo.',
    invalid_name: 'Name is invalid'
  },
  documents: {
    download: 'View/Download',
    new: 'New Document',
    permissions: 'Permissions',
    saved: 'Document Saved',
    search: 'Search Documents',
    type: 'File Type',
    type_select: 'Select File Type',
    path: 'Group',
    path_select: 'Select Group',
    all: 'All Documents',
    audit: 'Audit Document',
    auditing: 'Auditing',
    edit: 'Edit Document',
    create: 'Create Document',
    created: 'Document Created',
    permission_select: 'Select Permissions',
    expiration: 'Document View Expiration',
    expiration_metric: 'minutes',
    lifetime: 'lifetime',
    helper: 'Click or drag to upload document',
    empty_state: 'No documents found.',
    back_to_index: 'Back to Documents',
    copy_link: 'Copy Link',
    link_copied: 'Document link copied to clipboard'
  },
  changes: {
    reset: 'Reset Changes',
    conflicting: 'Conflicting Changes',
    resolve_modal: 'Your changes conflict with the master website content and cannot be submitted unless conflicts are resolved. Please contact an admin or the site web developer to resolve this conflict and proceed. Or, reset your work to match the master files.',
    resolve_files: 'The files you have changed since the conflict occurred are:',
    title: 'Title',
    user: 'User',
    preview: 'Preview',
    actions: 'Actions',
    approve: 'Approve',
    approved: 'Aprobado',
    deny: 'Deny',
    empty_state: 'There are no pending changes for this repo.',
    pr_pending_title: '[PENDING] Changes from:',
    pr_pending_body: 'Pending changes from:',
    pr_ready_title: '[READY] Changes from:'
  },
  submissions: {
    file: 'File',
    size: 'Size',
    back_to_index: 'Back to submissions',
    email: 'Email',
    respond: 'Respond',
    archive: 'Archive',
    contacts: 'Contacts',
    archived: 'Archived',
    approved: 'Approved',
    existing: 'Existing',
    pending: 'Pending',
    empty_state: 'No reports found.',
  },
  editor: {
    new: 'New',
    save: 'Save',
    save_title: 'Save Changes',
    duplicate_title: 'Duplicate',
    delete_title: 'Delete',
    rename: 'Rename'
  },
  form: {
    change: 'Change',
    upload: 'Upload'
  },
  notify: {
    confirm: 'Are you sure?',
    changes_submitted: 'Changes submitted for approval',
    item_saved: 'Item saved.',
    merge_conflict_resolve: 'has been updated to the latest published version.',
    item_deleted: 'Item deleted.'
  },
  footer: {
    powered_by: 'Powered by',
    support_msg: 'For support message',
    copyright: 'Copyright'
  }
};