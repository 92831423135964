export const es = {
  general: {
    new: 'Nuevo',
    done: 'Listo',
    open: 'Abrir',
    save: 'Guardar',
    edit: 'Editar',
    close: 'Cerrar',
    search: 'Buscar',
    file: 'Archivo',
    filter: 'Filtrar',
    cancel: 'Cancelar',
    create: 'Crear',
    select: 'Seleccionar',
    building: 'Construyendo',
    deploying: 'Desplegando',
    loading: 'Cargando',
    last_updated: 'Última actualización',
    form_data: 'Form Data',
    date_format: 'DD/MM/AAAA',
    email: 'Correo',
    name: 'Nombre',
    country: 'País',
    message: 'Mensaje',
    type: 'Tipo',
    home: 'Hogar',
    welcome: 'Bienvenidas',
    not_found: 'No Encontrada',
    oops: 'Uy'
  },
  permissions: {
    admin: 'Admin',
    push: 'Editor',
    pull: 'Público'
  },
  navbar: {
    preview: 'Vista Previa',
    submit: 'Someter Cambios',
    update: 'Actualizar Cambios',
    pending: 'Pendiente',
    submit_title: 'Someter cambios a aprobar.',
    update_title: 'Actualizar cambios a aprobar.',
    pending_title: 'Cambio(s) pendiente(s) aprobación.',
    language: 'Idioma',
    source: 'Fuente',
    login: 'Iniciar con',
    exit: 'Salir',
		docs: 'Documentos'
  },
  sidebar: {
    repos: 'Repositorios',
    users: 'Usuarios',
    changes: 'Cambios',
    documents: 'Documentos',
    collections: 'Colecciones',
    submissions: 'Formularios'
  },
  repos: {
    list_title: '¡Bienvenido! Por favor seleccione un repositorio.',
    select: 'Seleccione Repositorio'
  },
  collections: {
    entry_name: 'Nombre',
    empty_state: 'Ningún coleción encontrada.',
    invalid_name: 'Nombre inválido'
  },
  users: {
    revoke: 'Revocar',
    access: 'Aceso',
    active: 'Activo',
    disabled: 'Deshabilitado',
    blocked: 'Bloqueado',
    name: 'Nombre',
    preview: 'Vista Previa',
    status: 'Estado',
    empty_state: 'No hay usuarios para este repositorio.'
  },
  documents: {
    download: 'Ver/Bajar',
    new: 'Nuevo Documento',
    permissions: 'Permisos',
    saved: 'Documento Guardado',
    search: 'Buscar Documentos',
    type: 'Formato de Archivo',
    type_select: 'Formato de Archivo',
    path: 'Grupo',
    path_select: 'Grupo',
    all: 'Todos Documentos',
    audit: 'Auditar Documento',
    auditing: 'Auditando',
    edit: 'Editar Documento',
    create: 'Crear Nuevo Documento',
    created: 'Documento Creado',
    permission_select: 'Seleccionar Permisos',
    expiration: 'Expiración de Vista del Documento',
    expiration_metric: 'minutos',
    lifetime: 'vida',
    helper: 'Click or drag to upload document',
    empty_state: 'Ningún documento encontrado.',
    back_to_index: 'Regresar a Documentos',
    copy_link: 'Copiar Enlace',
    link_copied: 'Enlace del documento copiado al portapapeles'
  },
  changes: {
    reset: 'Reiniciar Cambios',
    conflicting: 'Cambios Conflictivos',
    resolve_modal: 'Sus cambios entran en conflicto con el contenido maestro del sitio web y no se pueden enviar a menos que se resuelvan los conflictos. Póngase en contacto con un administrador o el desarrollador del sitio web para resolver este conflicto y continuar. O reinicie su trabajo para que coincida con los archivos maestros.',
    resolve_files: 'Los archivos que usted ha cambiado desde que ocurrió el conflicto son:',
    title: 'Título',
    user: 'Usuario',
    preview: 'Vista Previa',
    actions: 'Acciones',
    approve: 'Aprobar',
    approved: 'Aprobado',
    deny: 'Negar',
    empty_state: 'No hay cambios pendientes para este repositorio.',
    pr_pending_title: '[PENDIENTE] Cambios de:',
    pr_pending_body: 'Pendiente cambios de:',
    pr_ready_title: '[LISTO] Cambios de:'
  },
  submissions: {
    file: 'Archivo',
    size: 'Tamaño',
    back_to_index: 'Regresar a Denuncias',
    email: 'Correo',
    respond: 'Responder',
    archive: 'Archivar',
    contacts: 'Contactos',
    archived: 'Archivadas',
    approved: 'Aprobadas',
    existing: 'Existentes',
    pending: 'Pendientes',
    empty_state: 'Ninguna denuncia encontrada.',
  },
  editor: {
    new: 'Nuevo',
    save: 'Guardar',
    save_title: 'Guardar Cambios',
    duplicate_title: 'Duplicar',
    delete_title: 'Borrar',
    rename: 'Cambiar Nombre'
  },
  form: {
    change: 'Cambiar',
    upload: 'Subir'
  },
  notify: {
    confirm: '¿Estás seguro?',
    changes_submitted: 'Cambios sometidos para aprobación',
    item_saved: 'Artículo guardado.',
    merge_conflict_resolve: 'se ha actualizado a la última versión publicada.',
    item_deleted: 'Artículo borrado.'
  },
  footer: {
    powered_by: 'Propulsado por',
    support_msg: 'Para ayuda contacta a',
    copyright: 'Derechos de autor'
  }
};